import { ACTIONS, ENDPOINTS, CALL_API, HTTP_METHODS } from "./../constants"


export const contactUs = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.CONTACT_US_INIT,
        success: ACTIONS.CONTACT_US_SUCCEDED,
        fail: ACTIONS.CONTACT_US_FAILED
      }, 
     
      endpoint: ENDPOINTS.CONTACT_US,
      method: HTTP_METHODS.POST
    }
  })

  export const reclameUs = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.FETCH_FEEDBACKS_INIT,
        success: ACTIONS.FETCH_FEEDBACKS_SUCCEDED,
        fail: ACTIONS.FETCH_FEEDBACKS_FAILED
      }, 
     
      endpoint: ENDPOINTS.FETCH_FEEDBACKS,
      method: HTTP_METHODS.POST
    }
  })

  export const returnProduct = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.RETURNPRODUCT_INIT,
        success: ACTIONS.RETURNPRODUCT_SUCCEDED,
        fail: ACTIONS.FETCH_FEEDBACKS_FAILED
      }, 
     auth: true,
      endpoint: ENDPOINTS.RETURNPRODUCT,
      method: HTTP_METHODS.POST
    }
  })
