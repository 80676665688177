/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import MenuCart from "./sub-components/MenuCart";
import {
  /*checkoutRoute, */ myCartRoute,
  compareRoute,
  wishlistRoute,
} from "../../routes";
import { deleteFromCart } from "../../store/actions";
import { connect, shallowEqual, useSelector } from "react-redux";
import FormattedMessage from "../common/FormattedMessage";
import Notification from "./sub-components/Notification";


const IconGroup = ({
  iconWhiteClass,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
}) => {
  const cartRef = useRef();
  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };
  const { totalNotifications, notifications,isAuthenticated } = useSelector(
    (state) => ({
      totalNotifications: state.user.totalNotifications,
      notifications: state.user.notifications,
      isAuthenticated: state.authentication.isAuthenticated,
    }),
    shallowEqual
  );
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const closeMenuCart = () => {
    if (cartRef && cartRef.current) {
      cartRef.current.classList.remove("active");
    }
  };

  const handleClickOutside = (event) => {
    if (cartRef && !cartRef.current.contains(event.target)) {
      closeMenuCart();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      {/*<div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={e => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div>*/}
      {/*<div className="same-style account-setting d-none d-lg-block">
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          <ul>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>Login</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                Register
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/my-account"}>
                my account
              </Link>
            </li>
          </ul>
        </div>
      </div>
     */}{" "}
      <div className="same-style header-compare">
        <Link to={compareRoute.path}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={wishlistRoute.path}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div>



      {/* //NOTIFICATION */}
    { isAuthenticated &&
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={(e) => handleClick(e)}>
        <i className="pe-7s-mail" />
          <span className="count-style">
            {notifications && Array.isArray(notifications) ? notifications.filter(notification => !notification.seen).length  : 0}
          </span>
        </button> 
        
        <Notification
          forwardRef={cartRef}
          closeMenuCart={closeMenuCart}
         
          deleteFromCart={deleteFromCart}
        /> 
       </div> 
       


    }

      
      {/* /// */}



      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart
          forwardRef={cartRef}
          closeMenuCart={closeMenuCart}
          cartData={cartData}
          deleteFromCart={deleteFromCart}
        />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={myCartRoute.path}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </Link>
      </div>
      {/*<div className="same-style header-compare">
        <Link to={checkoutRoute.path}>
          <i className="pe-7s-cart" />
        </Link>
      </div>*/}
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    cartData: state.cart.products,
    wishlistData: state.wishlist,
    compareData: state.compare,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item, addToast) => {
      addToast(<FormattedMessage id="NOTIFICATION.CART_UPDATED" />, {
        appearance: "success",
        autoDismiss: true,
      });
      dispatch(deleteFromCart(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
