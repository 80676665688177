import React, { useEffect } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {aboutUsRoute, categoryProductsRoute, contactUsRoute, homeRoute, productsRoute} from "../../../routes";
import { fetchCategories } from "../../../store/actions";
import FormattedMessage from "../../common/FormattedMessage";

const MobileNavMenu = ({sidebarMenu}) => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])
  const {categories = []}  = useSelector((state) => ({
    categories: state.product.categories
  }))
  

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
      <li className='nav-item'>
            <Link to={homeRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.HOME' />
            </Link>
          </li>
          <li className='menu-item-has-children'>
            <Link to={productsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.SHOP' />
            </Link>
               <ul className="sub-menu">
                 {categories?.map((categorie,key) => {
                   return (
                    <li className='nav-item' key = {key}>
                    <Link to={categoryProductsRoute.path.replace(":category", categorie.id )}>
                      {categorie.title}
                    </Link>
                  </li>
                   )}
                   )  }
                </ul>
              </li>
          {/* <li className="nav-item">
                      <Link to={eventsRoute.path} className="nav-link">
                        <FormattedMessage id="MENU.EVENT" />
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={bookingRoute.path} className="nav-link">
                        <FormattedMessage id="MENU.BOOKING" />
                      </Link>
                    </li> */}

          <li className='nav-item' >
            <Link to={aboutUsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.ABOUT_US' />
            </Link>
          </li>

          <li className='nav-item'>
            <Link to={contactUsRoute.path} className='nav-link'>
              <FormattedMessage id='MENU.CONTACT_US' />
            </Link>
          </li>

        
      </ul>
    </nav>
  );
};
MobileNavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
}
export default MobileNavMenu
