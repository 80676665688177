/* eslint-disable no-unused-vars */
// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { makeCall } from '../helpers';
import { store } from '../store';
import { HTTP_METHODS } from '../store/constants';
import { FCM_TOKEN } from '../store/constants/endpointsConstant';


const firebaseConfig = {
  apiKey: "AIzaSyDd5R_Dk9pwgUeEhFpXm-d0uYk8f2f4ISw",
  authDomain: "sancella-6941c.firebaseapp.com",
  projectId: "sancella-6941c",
  storageBucket: "sancella-6941c.appspot.com",
  messagingSenderId: "448965298842",
  appId: "1:448965298842:web:1715ed66aacc05e4692d23",
  measurementId: "G-GNNKCL3PKW"
};


const app = initializeApp(firebaseConfig);


const messaging = getMessaging();

export const requestForToken = () => {
  const { token } = store.getState().authentication || {}

  return getToken(messaging)
    .then((currentToken) => {
      if (currentToken) {
        makeCall(HTTP_METHODS.POST, FCM_TOKEN, { token: currentToken }, {'Authorization': `Bearer ${token.access_token}`})
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
     
        // Perform any other neccessary action with the token
     
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
        console.log("payload", payload)
      resolve(payload);
    });
  });