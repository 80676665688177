/* eslint-disable import/no-anonymous-default-export */
import { ACTIONS } from "./../constants";

const initialState = {
    faq : [],
    isFetching: false, 
    success: false, 
    error: null 
};

export default (state = initialState, action) => {
    const { payload, type } = action;
  
    switch (type) {
      case ACTIONS.CLEAR_ERROR: {
        return { ...state, success: false, error: null, }
      }


      case ACTIONS.FETCH_FAQ_INIT: {
        return { ...state, isFetching: true, error: null };
      }
      case ACTIONS.FETCH_FAQ_SUCCEDED: {
        const { results } = payload
        return { ...state, isFetching: false, faq: results };
        
      }
      case ACTIONS.FETCH_FAQ_FAILED: {
        return { ...state, isFetching: false, error: payload };
      }
      default: {
        return state
      }
  
    }}