import axios from 'axios'
import { API_BASE_URL } from './../store/constants'

export class APIRequest {

  constructor(baseURL){
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  sendRequest = (method, url, data = {}, headers = {}, params = {}) =>  this.client.request({
    method,
    data,
    url,
    headers,
    params
  })

}

const client = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})
export const makeCall = (method, url, data = {}, headers = {}, params = {}, extra = {}) => {
  return client.request({
    method,
    data,
    url,
    headers,
    params,
    ...extra
  })
}