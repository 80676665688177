/* eslint-disable import/no-anonymous-default-export */
import { ACTIONS } from "./../constants";

import { PURGE } from "redux-persist"
const initialState = {
  isFetching: false,
  isLoading: false,
  success: false,
  tva : {},
  fodec: {},
  error: null,
  
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_ERROR: {
      return { ...state, success: false, error: null };
    }

    case ACTIONS.LOGOUT_SUCCEDED: {
        return { ...state, ...initialState };
      }
      case PURGE: {
        return { ...state, ...initialState };
      }

    case ACTIONS.FETCH_TAXATION_INIT: {
        return { ...state, isFetching: true, error: null };
      }
      case ACTIONS.FETCH_TAXATION_SUCCEDED: {
        const { results } = payload;
        return {
          ...state,
          isFetching: false,
          tva: results.find((item) => item.type === "tva"),
          fodec: results.find((item) => item.type === "fodec"),
        };
      }
      case ACTIONS.FETCH_TAXATION_FAILED: {
        return { ...state, isFetching: false, error: payload };
      }


    default: {
      return state;
    }
  }
};
