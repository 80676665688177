import PropTypes from "prop-types";
import React from "react";
//import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
import FormattedMessage from "../common/FormattedMessage";
import {loginRoute, myAccountRoute, myOrderRoute , devisRoute ,createReclamationRoute} from "../../routes";
import {Link} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/actions";


const HeaderTop = ({ borderStyle,marginTop,marginRight }) => {

  const dispatch = useDispatch()

  const { isAuthenticated } = useSelector((state) => ({ isAuthenticated: state.authentication.isAuthenticated }), shallowEqual)

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <div className="responsive">
    <div

      className={`p-2 ${

        borderStyle === "fluid-border" ? "border-bottom" : ""

      }`}
      style={{marginTop:marginTop}}

    >


      <div className="row align-items-center">
        <div className="col-lg-4 col-md-4">
          <ul className="header-contact-info">
            <li>
              {/*<FormattedMessage id="WELECOM.TITLE" />*/}
            </li>

            {/*<li>
                    <LanguageCurrencyChanger

        currency={currency}

        setCurrency={setCurrency}

        currentLanguageCode={currentLanguageCode}

        dispatch={dispatch}

      />

                  </li>*/}
          </ul>
        </div>

        <div className="col-lg-8 col-md-8">
          <ul className="header-top-menu float-right">
            {isAuthenticated && (
              <>
                <li>
                  <Link to={myAccountRoute.path}>
                    <i className="bx bxs-user"></i>{" "}
                    <FormattedMessage id="MENU.MY_ACCOUNT" />
                  </Link>
                </li>
                <li>
                  <Link to={myOrderRoute.path}>
                    <i className="bx bxs-cart"></i>{" "}
                    <FormattedMessage id="MENU.MY_ORDERS" />
                  </Link>
                </li>

                <li>
                  <Link to={devisRoute.path}>
                    <i className="bx bxs-cart"></i>{" "}
                    <FormattedMessage id="MENU.DEVIS" />
                  </Link>
                </li>
                <li>
                  <Link to={createReclamationRoute.path}>
                    <i className="bx bxs-cart"></i>{" "}
                    <FormattedMessage id="MENU.RECLAMATION" />
                  </Link>
                </li>

              </>
            )}
            <li style={{marginRight:marginRight}}>
              {isAuthenticated ? (
                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout();
                  }}
                >
                  <i className="bx bx-log-in"></i>{" "}
                  <FormattedMessage id="MENU.LOGOUT" />
                </a>
              ) : (
                <Link to={loginRoute.path}>
                  <i className="bx bx-log-in"></i>{" "}
                  <i className="fa fa-sign-in mr-10" aria-hidden="true"></i>
                  <FormattedMessage id="MENU.LOGIN" />
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
  dispatch: PropTypes.func
};

export default HeaderTop

