import { isArray, isPlainObject, isString, isEmpty } from "lodash"

export const removeCSSClass = (ele, cls) => {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export const addCSSClass = (ele, cls) => {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const getTenantUrl = (images, index = 0) => {

  let pathname = undefined

  if (isEmpty(images)){
    return pathname
  }

  if (isArray(images)){
    pathname = !isEmpty(images[index]) ? images[index].url : pathname
  }else if (isPlainObject(images)){
    pathname = !isEmpty(images) ? images.url : pathname
  }else if (isString(images)){
    pathname = images
  }

  return window._ENV_.REACT_APP_API_BASE_URL + "/tenants/image/get?imgPath=" + pathname;
}

export const getProductURL = (images, index = 0) => {

  let pathname = undefined

  if (isEmpty(images)){
    return pathname
  }

  if (isArray(images)){
    pathname = !isEmpty(images[index]) ? images[index].url : pathname
  }else if (isPlainObject(images)){
    pathname = !isEmpty(images) ? images.url : pathname
  }else if (isString(images)){
    pathname = images
  }

  return window._ENV_.REACT_APP_API_BASE_URL + "/products/image/get?imgPath=" + pathname;
}

export const getEventURL = (pathname) =>
  window._ENV_.REACT_APP_API_BASE_URL +
  "/event/image/get?imgPath=" +
  pathname;
