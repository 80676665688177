import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { aboutUsRoute, contactUsRoute, productsRoute , confidentialityRoute, cookiesRoute, faqRoute } from "../../routes";
import {
  EMAIL,
  FB_LINK,
  INSTAGRAM_LINK,
  PHONE,
  TWITTER_LINK,
} from "../../ui-helpers";
import FormattedMessage from "../../components/common/FormattedMessage";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const currentYear = new Date().getFullYear();
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const scrollToDiv = () => {
    var elmnt = document.getElementById("content");
    elmnt.scrollIntoView();
  }

  return (
    <footer
      className={`footer-area ${backgroundColorClass || ""} ${
        spaceTopClass || ""
      } ${spaceBottomClass || ""} ${extraFooterClass || ""} ${
        spaceLeftClass || ""
      } ${spaceRightClass || ""}`}
    >
      <div className={`${containerClass || "container"}`}>
        <div className="row">

          
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-3" : "col-lg-3 col-sm-3"
            }`}
          >
            <div className="footer-widget mb-30 ml-30">
              {/* footer copyright */}
              <div className="footer-title">
                <h3>
                  <FormattedMessage id="MENU.ABOUT_US" />
                </h3>
              </div>
              <div className="footer-list">
                <ul className="footer-contact-info">
                  <li>
                    <i
                      className="fa fa-map-marker "
                      style={{ color: "#4267B2" }}
                    />
                    <FormattedMessage id="ADDRESS.ONE" />
                  </li>
                  {/* <li>
                    <i className="pe pe-7s-shopbag mr-2" />
                    <FormattedMessage id="ADDRESS.TWO" />
                  </li> */}
                  <li>
                    <i
                      className="fa fa-phone mr-2"
                      style={{ color: "#4267B2" }}
                    />
                    {PHONE}
                  </li>
                  <li>
                    <i
                      className="fa fa-envelope mr-2"
                      style={{ color: "#4267B2" }}
                    />
                    {EMAIL}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-3" : "col-lg-3 col-sm-3"
            }`}
          >
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>
                  <FormattedMessage id="ABOUT_US.TITLE" />
                </h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={aboutUsRoute.path}>
                      <FormattedMessage id="MENU.ABOUT_US" />
                    </Link>
                  </li>
                  <li>
                    <Link to={contactUsRoute.path}>
                      <FormattedMessage id="MENU.CONTACT_US" />
                    </Link>
                  </li>
                  <li>
                    <Link to={productsRoute.path}>
                      <FormattedMessage id="MENU.SHOP" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
                {/* General conditions */}
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-3" : "col-lg-3 col-sm-3"
            }`}
          >
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>
                  <FormattedMessage id="Mentions légales"/>
                </h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={confidentialityRoute.path}>
                      <FormattedMessage id="Conditions générales de vente" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={confidentialityRoute.path + "#terms"}>
                      <FormattedMessage id="Conditions d'utilisation" />
                    </Link>
                  </li> */}
                  <li>
                    <Link to={cookiesRoute.path}>
                      <FormattedMessage id="Politique de confidentialité et Conditions d'utilisation" />
                    </Link>
                  </li>
                  <li>
                    <Link to={faqRoute.path}>
                      <FormattedMessage id="FAQ" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
                {/* General conditions end */}
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-3" : "col-lg-3 col-sm-6"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-145"
                  : "footer-widget mb-30 ml-75"
              }`}
            >
              <div className="footer-title">
                <h3>
                  <FormattedMessage id="FOLLOW_US" />
                </h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <div></div>
                    <i
                      className="fa fa-globe mr-10 "
                      style={{ color: "#4267B2" }}
                    />
                    <a href={FB_LINK} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage id="WEB.TITLE" />
                    </a>
                  </li>
                  {/* <li>

                  <li><a href={ FB_LINK }><i className='fa fa-facebook ' style={{color :"#4267B2"}} /></a></li>
          <li><a href={ INSTAGRAM_LINK }><i className='fa fa-linkedin'style={{color :"#0077b5"}}  /></a></li>
          <li> <a href={ TWITTER_LINK }> <i className='fa fa-twitter' style={{color :"#00acee"}} /> </a> </li>
                    <a
                      href={TWITTER_LINK}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FormattedMessage id="TWITTER.TITLE" />
                    </a>
            </li> */}
                  <li>
                    <i
                      className="fa fa-linkedin mr-10"
                      style={{ color: "#0077b5" }}
                    />
                    <a
                      href={INSTAGRAM_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="INSTAGRAM.TITLE" />
                    </a>
                  </li>
                  <li>
                    <i
                      className="fa fa-twitter mr-10"
                      style={{ color: "#00acee" }}
                    />
                    <a
                      href={TWITTER_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="TWITTER.TITLE" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom text-center">
        <div className="container">
          <div className="copyright-2 d-flex">
            <div className="p-2">
              <p className="text-gray">
                © {currentYear}{" "}
                <FormattedMessage id="FOOTER.ALL_RIGHT_RESERVED" />
              </p>
            </div>
            <div className="ml-auto p-2">
              Made by
              <a
                href="https://infinitymgt.fr"
                rel="noopener noreferrer"
                target="_blank"
              >
                {" "}
                Infinity Management
              </a>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up" /> 
      </button>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
