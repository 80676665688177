import { ACTIONS } from "./../constants";
import { uniqBy } from "lodash";

const initialState = {
  products: [],
  newProducts: [],
  discountedProducts: [],
  categories: [],
  families : [],
  discount :[],
  colors: [],
  brochure :[],
  brands: [],
  isCreatedRate : false,
  productQualities:[],
  searchTerm: "",
  hasMore: true,
  product: null,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  isFetching: false,
  isLoading: false,
  isCreatedOrder : false,
  isCreatedQuote: false,
  isConfirmedOrder : false,
  error: null,
  relatedProducts: [],
  productPackType:[],
  productSystems : []
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_ERROR: {
      return { ...state, success: false, error: null, isCreatedRate: false, isCreatedOrder : false, isCreatedQuote: false, isConfirmedOrder : false }
    }

    case ACTIONS.FETCH_PRODUCTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }

    case ACTIONS.FETCH_PRODUCTS_SUCCEDED: {
      const {
        results : products = [],
        count : totalItems = 0,
        totalPages = 1,
      } = payload;
      return {
        ...state,
        isFetching: false,
       
        products: products.map((prod) => ({
          ...prod,
        })),
        totalItems,
        currentPage:
          products.length > 0 ? state.currentPage + 1 : state.currentPage,
        totalPages,
      };
    }
    case ACTIONS.FETCH_PRODUCTS_FAILED: {
      return { ...state, isFetching: false, hasMore: false, error: payload };
    }

    //related products
    case ACTIONS.FETCH_RELATEDPRODUCT_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_RELATEDPRODUCT_SUCCEDED: {
      const stock =
        payload.availableColors.length > 0 && payload.availableSizes.length > 0
          ? 20
          : 0;
      const variation = payload.availableColors
        .filter(
          (color) =>
            payload.variants
              .filter(
                (element) =>
                  element.color.title === color.title && element.quantity > 0
              )
              .map((variant) => variant.quantity)
              .reduce((a, b) => a + b, 0) > 0
        )
        .map((colorObj) => ({
          color: colorObj.value,
          colorLabel: colorObj.title,
          size: payload.variants
            .filter(
              (element) =>
                element.color.title === colorObj.title && element.quantity > 0
            )
            .map((variant) => ({
              name: variant.size.title,
              stock: variant.quantity,
            })),
        }));
      const product = { ...payload, variation, stock };
      const allProducts = uniqBy([...state.relatedProducts, product], "id");
      return { ...state, isFetching: false, relatedProducts: allProducts };
    }
    case ACTIONS.FETCH_RELATEDPRODUCT_FAILED: {
      return { ...state, isFetching: false, hasMore: false, error: payload };
    }

    case ACTIONS.ADD_TO_CART: {
      const { quantity, variant, selectedProductSize } = payload
      // const size = variant.size.find((item) => item.name === selectedProductSize)
      
      if (state.product && state.product.variation) {
        const product = { ...state.product, variation: state.product.variation.map((variant) => ({ ...variant, size: variant.size.map((size) => selectedProductSize !== size.name ? size : ({ ...size, stock: (size.stock - quantity)}))}))}
        return { ...state, product }
      }

      return state
    }

    case ACTIONS.FETCH_PRODUCT_INIT: {
      return { ...state, isFetching: true, error: null, relatedProducts: [] , product : null };
    }
    case ACTIONS.FETCH_PRODUCT_SUCCEDED: {
      return {
        ...state,
        isFetching: false,
        product: payload,
      };
    }
    case ACTIONS.FETCH_PRODUCT_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_SUCCEDED: {
      const { products = [] } = payload;
      return {
        ...state,
        isFetching: false,
        discountedProducts: products.map((prod) => ({
          ...prod,
          
        })),
      };
    }
    case ACTIONS.FETCH_DISCOUNT_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_DISCOUNT_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_DISCOUNT_SUCCEDED: {
      const { products = [] } = payload;
      console.log(products)
      return {
        ...state,
        isFetching: false,
        discount: products.map((prod) => ({
          ...prod,
          
        })),
      };
    }
    case ACTIONS.FETCH_IN_DISCOUNT_PRODUCTS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }


    case ACTIONS.FETCH_NEW_PRODUCTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_NEW_PRODUCTS_SUCCEDED: {
      const { results = [] } = payload;
      return {
        ...state,
        isFetching: false,
        newProducts: results.map((prod) => ({
          ...prod,
          
        })),
      };
    }
    case ACTIONS.FETCH_NEW_PRODUCTS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_CATEGORIES_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_CATEGORIES_SUCCEDED: {
      const {results = [] } = payload
      return { ...state, categories: results.sort(function(a,b){
        if(a.title < b.title) { return -1; }
    if(a.title > b.title) { return 1; }
    return 0;
      })};
    }
    case ACTIONS.FETCH_CATEGORIES_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FETCH_FAMILIES_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_FAMILIES_SUCCEDED: {
       const { results = [] } = payload
      return { ...state, families: results.sort(function(a,b){
        if(a.title < b.title) { return -1; }
    if(a.title > b.title) { return 1; }
    return 0;
      })};
    }
    case ACTIONS.FETCH_FAMILIES_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FETCH_PRODUCTPACKTYPES_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_PRODUCTPACKTYPES_SUCCEDED: {
       const { results = [] } = payload
      return { ...state, productPackType: results.sort(function(a,b){
        if(a.title < b.title) { return -1; }
    if(a.title > b.title) { return 1; }
    return 0;
      })};
    }
    case ACTIONS.FETCH_PRODUCTPACKTYPES_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FETCH_PRODUCTSYSTEMS_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_PRODUCTSYSTEMS_SUCCEDED: {
       const { results = [] } = payload
      return { ...state, productSystems: results.sort(function(a,b){
        if(a.title < b.title) { return -1; }
    if(a.title > b.title) { return 1; }
    return 0;
      })};
    }
    case ACTIONS.FETCH_PRODUCTSYSTEMS_FAILED: {
      return { ...state, error: payload };
    }



    case ACTIONS.FETCH_COLORS_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_COLORS_SUCCEDED: {
      const { results = [] } = payload
      return { ...state, colors: results.sort(function(a,b){
        if(a.title < b.title) { return -1; }
    if(a.title > b.title) { return 1; }
    return 0;
      })};
    }
    case ACTIONS.FETCH_COLORS_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FETCH_SIZES_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_SIZES_SUCCEDED: {
      return { ...state, sizes: payload?.size ?? [] };
    }
    case ACTIONS.FETCH_SIZES_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FETCH_PRODQUALITYS_INIT: {
      return { ...state, error: null };
    }
    case ACTIONS.FETCH_PRODQUALITYS_SUCCEDED: {
      const { results = [] } = payload
      return { ...state, productQualities: results.sort(function(a,b){
        if(a.title < b.title) { return -1; }
    if(a.title > b.title) { return 1; }
    return 0;
      })};
    }
    case ACTIONS.FETCH_PRODQUALITYS_FAILED: {
      return { ...state, error: payload };
    }

    case ACTIONS.FILTER_PRODUCTS: {
      return { ...state, searchTerm: payload, hasMore: true };
    }

    case ACTIONS.CHECKOUT_ORDER_INIT: {
      return { ...state, isFetching: true, isCreatedOrder: false, error: null };
    }
    case ACTIONS.CHECKOUT_ORDER_SUCCEDED: {
      return { ...state, isFetching: false, isCreatedOrder: true };
    }
    case ACTIONS.CHECKOUT_ORDER_FAILED: {
      return { ...state, isFetching: false, isCreatedOrder: false, error: payload };
    }


    case ACTIONS.CONFIRM_ORDER_INIT: {
      return { ...state, isFetching: true, isConfirmedOrder: false, error: null };
    }
    case ACTIONS.CONFIRM_ORDER_SUCCEDED: {
      return { ...state, isFetching: false, isConfirmedOrder: true };
    }
    case ACTIONS.CONFIRM_ORDER_FAILED: {
      return { ...state, isFetching: false, isConfirmedOrder: false, error: payload };
    }


    case ACTIONS.CHECKOUT_QUOTES_ORDER_INIT: {
      return { ...state, isFetching: true, isCreatedQuote: false, error: null };
    }
    case ACTIONS.CHECKOUT_QUOTES_ORDER_SUCCEDED: {
      return { ...state, isFetching: false, isCreatedQuote: true };
    }
    case ACTIONS.CHECKOUT_QUOTES_ORDER_FAILED: {
      return { ...state, isFetching: false, isCreatedQuote: false, error: payload };
    }

    case ACTIONS.RATE_ORDER_INIT: {
      return { ...state, isFetching: true, isCreatedRate: false, error: null };
    }
    case ACTIONS.RATE_ORDER_SUCCEDED: {
      return { ...state, isFetching: false, isCreatedRate: true };
    }
    case ACTIONS.RATE_ORDER_FAILED: {
      return { ...state, isFetching: false, isCreatedRate: false, error: payload };
    }
    default: {
      return state;
    }
  }
};
